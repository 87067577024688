/*======================================
BACKGROUND
======================================*/
.bg-navbar {
    background-image: linear-gradient(to top,rgb(11, 97, 255) 20%,rgb(99, 154, 255) 100%);
    box-shadow:  4px 4px 10px 0 rgba(211, 230, 255, 0.13), 4px 4px 15px -5px rgb(81, 142, 255);
    color: rgb(255, 255, 255);
    z-index: 1;
    border-radius: 50px;
    min-height: 99vh;
    max-height: 99vh;
    margin-left: 4px;
    overflow: auto;
}
/*======================================
BACKGROUND
======================================*/
/*======================================
NAV
======================================*/
.nav-itemm .nav-linkk {
    padding: 10px 10px 0 10px;
    width: 100%;
    display: block;
    text-align: left;
    color: rgb(255, 255, 255);
    transition: all 0.5s;
}
.nav-linkk:hover{
    color: rgb(219, 219, 219);
    transform: scale(0.9);
}
.nav-linkk i {
    font-size: 0.85rem;
    margin-right: 0.4rem;
    margin-left: 0.5rem;
}
.nav-linkk span {
    font-size: 0.85rem;
}
.navbar-toggler{
    transition: all 1s;
}
.navbar-toggler:focus{
    border: none;
    box-shadow: none;
    transform: rotate(360deg);
}
.navbar-toggler:hover{
    transform: rotate(180deg);
}
/*======================================
NAV
======================================*/
/*======================================
SIDEBAR
======================================*/
.sidebar-heading{
    color: rgb(255, 255, 255);
    margin: 20px 0 -3px 0 ;
    padding: 0 0 0 10px;
    font-weight: 700;
    font-size: 10pt;
    text-align: left;
}
/*======================================
SIDEBAR
======================================*/
/*======================================
WRAPPER
======================================*/
#wrapper {
    display: flex;
}
#content-wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-x: center;
    width: 100%;
    overflow-x: hidden;
}
/*======================================
WRAPPER
======================================*/